@import "mixins"
.homePageItem
  background: var(--home_tournamentItemBg)
  border-radius: 4px
  padding: var(--home_tournamentItemPadding)
  margin-bottom: var(--home_tournamentItemMargin)
  color: rgb(var(--secondaryColor))
  min-height: calc(var(--homeGameItemHeight) + var(--home_tournamentItemMargin) + 48px)
  position: relative

.header
  @include flex
  height: 48px
  padding: 0 3px
  > a
    color: rgb(var(--primaryColor))
    white-space: nowrap
    &:hover
      color: rgb(var(--secondaryColor))

.headerSport
  @include flex
  background: var(--home_headerSportBg)
  border-radius: 30px
  padding: 6px 12px
  margin-right: 20px
  min-width: 110px
  height: 32px
  text-transform: uppercase
  white-space: nowrap
  color: var(--home_headerSportColor)
  & :global
    .svg-sport-icon
      margin-right: 6px
      width: 20px
      height: 20px
      border-radius: 100%
      background: var(--home_headerSportIconBg)
      border: 1px solid var(--home_headerSportIconBorder)
      box-shadow: var(--sidebar_sportIconShadow)
      padding: 1px
      svg
        path
          fill: var(--home_headerSportColor) !important

.homeGames
  height: var(--homeGameItemHeight)
  position: relative
  > div
    width: 100%
    height: 100%
    position: absolute
    left: 0
    & :global
      .slick-track
        min-width: 100%
        display: flex
      .slick-prev,
      .slick-next
        left: unset !important
        top: -24px

      .slick-prev
        right: 34px !important

      .slick-next
        right: 2px !important
